import React from "react";
import ReactDOM from "react-dom";
import ErrorBoundary from "components/error/ErrorBoundary";
import NotificationList from "components/notification-list";
import {getDefaultQueryClient} from "util/query";
import {QueryClientProvider} from "react-query";
import useWindowSize from "hooks/useWindowSize";

const queryClient = getDefaultQueryClient();
function  NotificationsList() {
  const {width} = useWindowSize();
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <NotificationList isMobileVersion={width < 576}/>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

const target = document.getElementById("notification-list");
if (target) {
  ReactDOM.render(
    <NotificationsList/>,
    target,
  );
}

const mobileTarget = document.getElementById("notification-list-mobile");
if (mobileTarget) {
  ReactDOM.render(
    <NotificationsList/>,
    mobileTarget,
  );
}
