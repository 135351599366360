import React from "react";
import styles from "./noNotificationCard.module.scss";
import noNotification from "/static/workfinder/images/no_notification.png";

export default function NoNotificationCard(): JSX.Element {
  return (
    <div className={styles.container}>
      <img className={styles.image} src={noNotification}/>
      <h4 className={styles.header}>Relax, you have 0 new notifications</h4>
      <p className={styles.content}>We will message you here as soon as you receive any. </p>
    </div>
  );
}
