import React, {useState} from "react";
import classNames from "classnames";
import moment from "moment";
import PropTypes from "prop-types";

import {offerInterviewModalInterface} from "components/dashboard/OfferInterviewModal";
import {chatModalInterface} from "components/shared/Modal/ChatModal";
import {biasModalInterface} from "components/shared/Modal/BiasModal";
import {compensationModalInterface} from "components/shared/Modal/CompensationModal";

import styles from "./notificationCard.module.scss";

import cv from "/static/workfinder/images/CV.png";
import linkedin from "/static/workfinder/images/linkedin-icon.png";
import workingrights from "/static/workfinder/images/workingrights.png";
import portfolio from "/static/workfinder/images/portfolio.png";
import microphone from "/static/workfinder/images/microphone.png";
import message from "/static/workfinder/images/message.png";
import exclamation from "/static/workfinder/images/exclamation.png";
import SBcircle from "/static/workfinder/images/SBcircle.png";
import moneyBag from "/static/workfinder/images/money_bag.png";
import positive_feedback from "/static/workfinder/images/positive_feedback.png";
import negative_feedback from "/static/workfinder/images/negative_feedback.png";


import {
  useActivityDeleteMutation,
} from "services/mutations";

export function DocumentCard(props: {title: any; notification: any; image: any}): JSX.Element | null {
  const {title, notification: {show_initials: showInitials, ...notification}, image} = props;
  const timestamp = moment(notification.timestamp);
  const isToday = timestamp.isSame(new Date(), "day");
  const isReceivedMessage = notification.verb === "received_message";
  const isFeedbackReceived = notification.verb === "positive_feedback_collected" || notification.verb === "negative_feedback_collected";
  const activityMutation = useActivityDeleteMutation();
  let notificationType;

  if (RegExp("([a-z]+_bias_[a-z]+_stage)").test(notification.verb)) {
    notificationType = notification.verb.split("_bias_")[0].replaceAll("_", " ");
    notificationType = "Type: " + notificationType.charAt(0).toUpperCase()+notificationType.slice(1) + " bias";
  }

  const [markDelete, setMarkDeleting] = useState(false);
  function setClearNotification() {
    setMarkDeleting(true);
    activityMutation.mutate(notification.id);
  }

  function viewNotifications(verb) {
    const candidate = notification.target?.candidate;
    let customDEI;

    switch (verb) {
    case "requested_new_time":
      candidate.project = notification.target.placement.associated_project.name;
      candidate.candidateMessage = notification.target.candidate_message;
      candidate.placementKey = notification.target.placement.id;
      return (
        <a
          className={classNames(styles.action, "float-right")}
          onClick={() => offerInterviewModalInterface.dispatch({
            candidate: candidate,
            notificationId: notification.id,
            requestTime: true,
          })}
        >
          Send new times
        </a>
      );
    case "gender_bias_application_stage":
      notification.action_object.pp_preferences?.gender_male == 0.5 &&
      notification.action_object.pp_preferences?.gender_female == 0.5 ? customDEI = true : customDEI = false;
      return (
        <a
          className={classNames(styles.action, "float-right")}
          onClick={() => biasModalInterface.dispatch({
            projectId: notification.action_object.uuid,
            customDEI: customDEI,
            type: "gender_bias",
            stage: "application_stage",
          })}
        >
          View
        </a>
      );
    case "gender_bias_interview_stage":
      notification.action_object.pp_preferences?.gender_male == 0.5 &&
        notification.action_object.pp_preferences?.gender_female == 0.5 ? customDEI = true : customDEI = false;
      return (
        <a
          className={classNames(styles.action, "float-right")}
          onClick={() => biasModalInterface.dispatch({
            projectId: notification.action_object.uuid,
            customDEI: customDEI,
            type: "gender_bias",
            stage: "interview_stage",
          })}
        >
            View
        </a>
      );
    case "ethnicity_bias_application_stage":
      /** we are only concernced with the default value as of now */
      customDEI = false;
      return (
        <a
          className={classNames(styles.action, "float-right")}
          onClick={() => biasModalInterface.dispatch({
            projectId: notification.action_object.uuid,
            customDEI: customDEI,
            type: "ethnicity_bias",
            stage: "application_stage",
          })}
        >
              View
        </a>
      );
    case "ethnicity_bias_interview_stage":
      customDEI = false;
      return (
        <a
          className={classNames(styles.action, "float-right")}
          onClick={() => biasModalInterface.dispatch({
            projectId: notification.action_object.uuid,
            customDEI: customDEI,
            type: "ethnicity_bias",
            stage: "interview_stage",
          })}
        >
            View
        </a>
      );
    case "received_message":
      return (
        <a className={classNames(styles.action, "float-right")} onClick={() =>
          chatModalInterface.dispatch({
            open: true,
            correspondentUUID: notification.action_object.user_id,
            correspondentName: notification.action_object.sender_full_name,
          })}
        >
          Reply
        </a>
      );
    default:
      return notification.target_url && <a className={classNames(styles.action, "float-right")} href={notification.target_url} target="_blank" rel="noreferrer">View</a>;
    }
  }
  function getFeedbackText(action_object) {
    if (action_object.full_name) {
      return action_object.full_name;
    }
    return action_object.email;
  }

  if (markDelete) {
    return null;
  }
  return (
    <div className={styles.notificationItem}>
      <div className="d-flex">
        <div className={classNames(styles.imageContainer, "text-center align-self-center col-2")} >
          <img className={classNames(styles.image, "my-auto")} src={image}/>
        </div>
        <div className={classNames(styles.notificationBody, "col-10 pr-1")}>
          <a className={classNames(styles.clear, "float-right")} onClick={() => setClearNotification()}>Clear</a>
          <h4 className={styles.header}>{title}</h4>
          {
            (notification.target ||  notification.action_object) ?
              <>
                <span className={styles.content}>{
                  isReceivedMessage ?
                    notification.action_object[showInitials === true ? "sender_title" : "sender_username"] :
                    isFeedbackReceived ? getFeedbackText(notification.action_object) :notification.target?.candidate.user[showInitials === true ? "initials" : "full_name"] || notificationType
                }
                </span>
                <br/>
              </>
              : ""
          }
          <span className={styles.subContent}>{isToday ? timestamp.format("h:mma"):timestamp.format("DD.MM.YYYY")}</span>
          {
            isReceivedMessage ?
              <a className={classNames(styles.action, "float-right")} onClick={() => chatModalInterface.dispatch({
                open: true,
                correspondentUUID: notification.action_object.sender,
                correspondentName: notification.action_object[showInitials === true ? "sender_title" : "sender_username"],
              })}
              >View</a> :
              viewNotifications(notification.verb)
          }
        </div>
      </div>
    </div>
  );
}

export function SalaryCard(props: {title: any; notification: any; image: any}): JSX.Element | null {
  const {title, notification, image} = props;
  const timestamp = moment(notification.timestamp);
  const isToday = timestamp.isSame(new Date(), "day");
  const activityMutation = useActivityDeleteMutation();

  const [markDelete, setMarkDeleting] = useState(false);
  function setClearNotification() {
    setMarkDeleting(true);
    activityMutation.mutate(notification.id);
  }
  function viewNotifications(verb) {

    switch (verb) {
    case "requested_salary range":
      return (
        <a
          className={classNames(styles.action, "float-right")}
          onClick={() => compensationModalInterface.dispatch({
            projectId: notification.target.uuid,
            roleName: notification.target.name,
          })}
        >
          View
        </a>
      );
      break;
    default:
      return notification.target_url && <a className={classNames(styles.action, "float-right")} href={notification.target_url} target="_blank" rel="noreferrer">View</a>;
    }
  }

  if (markDelete) {
    return null;
  }
  return (
    <div className={styles.notificationItem}>
      <div className="d-flex">
        <div className={classNames(styles.imageContainer, "text-center align-self-center col-2")} >
          <img className={classNames(styles.image, "my-auto")} src={image}/>
        </div>
        <div className={classNames(styles.notificationBody, "col-10 pr-1")}>
          <a className={classNames(styles.clear, "float-right")} onClick={() => setClearNotification()}>Clear</a>
          <h4 className={styles.header}>{title}</h4>
          <span className={styles.content}>{notification.target?.name}</span><br/>
          <span className={styles.subContent}>{isToday ? timestamp.format("h:mma"):timestamp.format("DD.MM.YYYY")}</span>
          {notification.verb && viewNotifications(notification.verb)}
        </div>
      </div>
    </div>
  );
}

export default function NotificationCard(props: {notification: any}): JSX.Element {
  const {notification} = props;

  return (
    <div className={styles.container}>
      { notification.verb == "responded_cv" && (
        <DocumentCard title="CV received"  notification={notification} image={cv}/>
      )}
      { notification.verb == "responded_linkedin" && (
        <DocumentCard title="Linkedin received"  notification={notification} image={linkedin}/>
      )}
      { notification.verb == "responded_working right" && (
        <DocumentCard title="Right to Work received"  notification={notification} image={workingrights}/>
      )}
      { notification.verb == "responded_portfolio" && (
        <DocumentCard title="Portfolio received"  notification={notification} image={portfolio}/>
      )}
      { notification.verb == "requested_new_time" && (
        <DocumentCard title="Interview time request"  notification={notification} image={microphone}/>
      )}
      { notification.verb == "received_message" && (
        <DocumentCard title="Message received" notification={notification} image={message}/>
      )}
      { notification.verb == "gender_bias_application_stage" && (
        <DocumentCard title="Bias detected" notification={notification} image={exclamation}/>
      )}
      { notification.verb == "gender_bias_interview_stage" && (
        <DocumentCard title="Bias detected" notification={notification} image={exclamation}/>
      )}
      { notification.verb == "ethnicity_bias_application_stage" && (
        <DocumentCard title="Bias detected" notification={notification} image={exclamation}/>
      )}
      { notification.verb == "ethnicity_bias_interview_stage" && (
        <DocumentCard title="Bias detected" notification={notification} image={exclamation}/>
      )}
      { notification.verb == "responded_skill level" && (
        <DocumentCard title="Skill level received" notification={notification} image={SBcircle}/>
      )}
      { notification.verb == "responded_tech skill level" && (
        <DocumentCard title="Skill level available" notification={notification} image={SBcircle}/>
      )}
      { notification.verb == "requested_salary range" && (
        <SalaryCard title="Compensation details requested" notification={notification} image={moneyBag}/>
      )}
      { notification.verb == "responded_salary range" && (
        <SalaryCard title="Compensation details available" notification={notification} image={moneyBag}/>
      )}
      { notification.verb == "positive_feedback_collected" && (
        <DocumentCard title="New feedback" notification={notification} image={positive_feedback}/>
      )}
      { notification.verb == "negative_feedback_collected" && (
        <DocumentCard title="New feedback" notification={notification} image={negative_feedback}/>
      )}
    </div>
  );
}
NotificationCard.propTypes = {
  notification: PropTypes.object,
};
