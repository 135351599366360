import React, {useState} from "react";
import moment from "moment";
import classNames from "classnames";

import LoadingSpinner from "components/shared/LoadingSpinner";
import {useNotificationQuery} from "services/queries";
import ClickAwayListener from 'react-click-away-listener';


import pageStyles from "./index.module.scss";
import NoNotificationCard from "./NoNotificationCard";
import NotificationCard from "./NotificationCard";
import {usePopper} from "react-popper";
import {
  useUserMutation,
} from "services/mutations";

function Notifications({onClose}): JSX.Element {
  const {data=[], isLoading, isSuccess} = useNotificationQuery();
  const [markLastNotification, setMarkLastNotification] = useState(false);
  const userMutation = useUserMutation();
  if (isSuccess && !markLastNotification) {
    userMutation.mutate(
      {last_notification_checked: moment().format()});
    setMarkLastNotification(true);
  }
  if (isLoading) {
    return <div className={classNames(pageStyles.container, "d-flex justify-content-center")}><LoadingSpinner/></div>;
  }
  return (
    <div className={pageStyles.container}>
      <div className={pageStyles.notificationList}>
        {data.results?.length ? data.results.map((notification, index) => (
          <NotificationCard notification={notification} key={index}/>
        )):<NoNotificationCard/>}
      </div>
    </div>

  );
}

export default function NotificationList({isMobileVersion}) {
  const [isOpen, setIsOpen] = useState(false);
  const [showCount, setShowCount] = useState(true);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const {styles, attributes} = usePopper(referenceElement, popperElement, {
    modifiers: [
      {name: "arrow", options: {element: arrowElement}},
      {
        name: "offset",
        options: {
          offset: [-32, 10],
        },
      },
    ],
  });
  const handleClick = () => {
    setIsOpen(!isOpen); // we need an event listener for the burger icon to set this to false when mobilenav expands
    setShowCount(false);
  };
  const handleClose = () => {
    if (isOpen == true) {
      setIsOpen(false);
    }
  };
  const notification_count = Variables.user?.notification_count;
  return (
    <ClickAwayListener onClickAway={() => (handleClose())}>
      <a ref={setReferenceElement} onClick={() => handleClick()} className="position-relative">
        <span
          className={classNames(
            isOpen || notification_count>0 ? pageStyles.iconOpen : pageStyles.icon,
            "material-icons",
          )}
          ref={setReferenceElement}
        >
          {notification_count>0 ? "notifications_active" : "notifications_none"}
        </span>
        {(showCount && notification_count>0 ) && <sup className={classNames(pageStyles.notificationCount, "position-absolute")} >
          <span>{notification_count}</span>
        </sup>}
      </a>

      {isOpen && (
        <div
          ref={setPopperElement}
          style={styles.popper}
          className={isMobileVersion && pageStyles.mobileContainer}
          {...attributes.popper}
        >
          <Notifications onClose={() => setIsOpen(false)}/>
          <div ref={setArrowElement} style={styles.arrow} />
        </div>
      )}
    </ClickAwayListener>
  );
}
